const projectsData = [
  {
    name: "GoScreen AI",
    date: "2 Weeks",
    appType: "Web App",
    teamOrSoloProject: "Solo Project",
    discription:
      "Crafted a dynamic web application that leverages ChatGPT to provide personalized movie recommendations. Share your preferences and discover curated suggestions in an engaging interface with all the details you need.",
    link: "https://goscreen-ai.web.app/",
    github: "https://github.com/youssefMoJo/GoScreen-AI",
    Technologies: ["React", "Firebase", "ChatGPT"],
    specialStyling: false,
    imageName: "GoScreen-AI.png",
  },
  {
    name: "Shorten URL",
    date: "2 Days",
    appType: "Chrome Extension",
    teamOrSoloProject: "Solo Project",
    discription:
      "Created and launched a Chrome extension for efficient web link sharing, with future feature updates planned. Successfully published the extension on the Chrome Web Store, expanding its accessibility.",
    link: "https://chromewebstore.google.com/detail/shorten-url/pkdhbhbeapnenbeihmabpgmeeinbdpgc",
    github: "https://github.com/youssefMoJo/Shorten-URL",
    Technologies: ["AWS (DynamoDB, API Gateway, Lambda)", "JavaScript"],
    specialStyling: false,
    imageName: "Group 28.png",
  },
  {
    name: "MyFlex",
    date: "2 Months",
    appType: "Web App",
    teamOrSoloProject: "Team Project",
    discription:
      "This web application intends to help you decide what movie to watch next with a recommendation feature and movie tracking system to enhance your viewing experience.",
    link: "http://ec2-3-141-165-79.us-east-2.compute.amazonaws.com:3333/",
    github: "https://github.com/MyFelx/App",
    Technologies: ["React", "Node", "MongoDB"],
    specialStyling: false,
    imageName: "NMyflex.png",
  },
  {
    name: "Sparkle Drive",
    date: "2 Weeks",
    appType: "Web App",
    teamOrSoloProject: "Solo Project",
    discription:
      "Developed and launched the website with dynamic pricing, SMS order management, and user-friendly features, enhancing the customer experience and revenue potential.",
    link: "https://sparkledrive.shop/",
    github: "https://github.com/youssefMoJo/carWash",
    Technologies: ["React", "Firebase (Realtime DB, Hosting)"],
    specialStyling: false,
    imageName: "NsparkleDrive.png",
  },
  {
    name: "Heart Disease Prediction",
    date: "1 Month",
    appType: "Machine Learning",
    teamOrSoloProject: "Team Project",
    discription:
      "Applied Machine Learning methods and data preparation techniques to pre-process raw medical data and construct multiple predictive models.",
    link: "https://www.kaggle.com/code/youssefmohamed21/heart-disease-prediction-using-ml",
    github: "https://github.com/ksfarag/Heart-Disease-Prediction-Using-ML",
    Technologies: ["Python (pandas, NumPy, matplotlib, scikit-learn)"],
    specialStyling: false,
    imageName: "NHeartDiseasePrediction.jpg",
  },
  {
    name: "Quotes",
    date: "1 Month",
    appType: "Mobile App",
    teamOrSoloProject: "Solo Project",
    discription:
      "This mobile application provides users with a pool of quotes, allowing them to add and remove quotes from their list and share them with others.",
    link: "https://www.youtube.com/watch?v=6JSGdTFC8Rc&ab_channel=youssefmohamed",
    github: "https://github.com/youssefMoJo/quotes-Using-AWS",
    Technologies: [
      "React Native",
      "AWS (DynamoDB, Cognito, API Gateway, Lambda)",
    ],
    specialStyling: true,
    imageName: "NQuotes.png",
  },
  {
    name: "FadfadA",
    date: "1 Month",
    appType: "Web App",
    teamOrSoloProject: "Solo Project",
    discription:
      "This web application allows users to enter a public chat room as well as send private messages to other members in the group.",
    link: "http://ec2-3-141-165-79.us-east-2.compute.amazonaws.com:3000/#/",
    github: "https://github.com/youssefMoJo/Fadfada",
    Technologies: ["React", "Node", "Socket IO"],
    specialStyling: false,
    imageName: "NFadfadA.png",
  },
  {
    name: "Burger Builder",
    date: "1 Month",
    appType: "Web App",
    teamOrSoloProject: "Udemy Course",
    discription:
      "This web application enables users to customize their burgers by adding ingredients, view a visual representation of the burger created with CSS, and place an order.",
    link: "https://jooburger-936ed.firebaseapp.com/",
    github: "https://github.com/youssefMoJo/JooBurger",
    Technologies: ["React", "Node", "Firebase (Realtime DB, Hosting)"],
    specialStyling: false,
    imageName: "NBurgerBuilder.png",
  },
];

export default projectsData;
